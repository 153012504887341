import { retryComparasionRequest, retryComparisonResponse } from "@/services/retry-comparison/retryComparisonService";

export function useRetryComparision() {
  const retryComparision = async (req: retryComparasionRequest) => {
    const result = await fetch(`/api/retry-comparision`, {
      body: JSON.stringify(req),
      method: "POST",
    });
    const { data } = await result.json();
    const resultJson = data as retryComparisonResponse;
    return resultJson;
  };
  return { retryComparision };
}