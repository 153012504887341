function sendLogToServer(message: string) {
  fetch('/api/client-log', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ message }),
  });
}

export function initializeLogCollector() {
  console.log('Log collector initialized');

  const consoleFunctions = ['log', 'error', 'trace', 'warn', 'info', 'debug', 'group', 'groupEnd'];

  // Almacenar las funciones originales
  const originalConsole: Record<string, Function> = {};

  consoleFunctions.forEach((funcName) => {
    // Guardar la referencia a la función original de console
    originalConsole[funcName] = (console as any)[funcName];

    // Sobrescribir la función de console
    (console as any)[funcName] = function (...args: any[]) {
      // Llamar a la función original
      originalConsole[funcName].apply(console, args);

      // Unir el mensaje y enviarlo al servidor
      const logMessage = args.join(' ');
      if(logMessage.trim() !== '')
        sendLogToServer(logMessage);
    };
  });
}