"use client";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import colors from "@/utils/colors";
import { Grid, IconButton, Typography, styled, Box, Divider } from "@mui/material";
import { UIPDFScrollProps } from "./UIPDFProps";
import UISmallArrowIcon from "@/components/icons/UISmallArrowIcon"
import UiExpandIcon from "@/components/icons/UiExpandIcon"
import UIText from "@/components/text/UIText";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const StyledIconButton = styled(IconButton)({
  color: colors.gray600,
  fontSize: "14px",
  "&:hover": { backgroundColor: "inherit" },
});

const UIPDFScrollViewer: React.FC<UIPDFScrollProps> = ({ scale, url, click, clickable, name, variant, isBlue }) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [dynamicScale, setDynamicScale] = useState<number>(scale);
  const containerRef = useRef<HTMLDivElement>(null);
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handleExpandClick = () => {
    if (containerRef.current) {
      if (containerRef.current.requestFullscreen && !fullScreen) {
        containerRef.current.requestFullscreen({ navigationUI: "show" });
        setFullScreen(true);
      }
      if(document.exitFullscreen && fullScreen){
        document.exitFullscreen();
        setFullScreen(false);
      }
    }
  };
  useEffect(() => {
    const calculateScale = async () => {
      if (!containerRef.current || !url) return;
      var dataPdf: Uint8Array | undefined;
      if (url.startsWith('data:application/pdf;base64,')) {
        dataPdf = Uint8Array.from(atob(url.replace('data:application/pdf;base64,', '')), c => c.charCodeAt(0))
      }

      const pdf = await pdfjs.getDocument(dataPdf ?? url).promise;
      if (!containerRef.current) return;
      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 1 });
      const containerWidth = containerRef.current.clientWidth - 80;
      let newScale = scale;
      let pageWidth = viewport.width * scale;
      while (pageWidth > containerWidth) {
        newScale -= 0.07;
        pageWidth = viewport.width * newScale;
      }
      setDynamicScale(newScale);
    };
    
    calculateScale();
    window.addEventListener("resize", calculateScale);
    return () => {
      window.removeEventListener("resize", calculateScale);
    };
  }, [url, pageNumber, scale]);

  return (
    <Grid container justifyContent={"center"} alignItems={"center"} paddingBottom={"16px"} width="100%" ref={containerRef}  sx={{ overflow: "auto" }}>
      { isBlue ? (
        <Grid item xs={12} sx={{ height: "72px", display: "flex", justifyContent: "space-between", alignItems: "center",  backgroundColor: colors.blue1, borderTopLeftRadius: "12px", borderTopRightRadius: "12px"}}>
          <Typography variant={variant} color={colors.white} sx={{ marginLeft: "50px"}}>
            {name}
          </Typography>
        </Grid> ) :
        (<Grid item xs={11}>
          <Typography variant={variant} color={colors.gray900}>
            {name}
          </Typography>
        </Grid>)
      }
     <Grid item xs={12} sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", p: 1, backgroundColor: isBlue ? "#102554" : "#F8F8F8", borderRadius: isBlue ? "0px" : "12px", overflow: "auto"}}>
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess} onClick={click} >
          <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
        </Document>
        <StyledIconButton onClick={handleExpandClick} sx={{ position: "absolute", backgroundColor: colors.white, top: 20, right: 20, padding: "8px", border: "1px solid #2E3C6F", borderRadius: "8px", transform: 'rotate(180deg)', display: "flex", justifyContent: "center", alignItems: "center"}}>
          <UiExpandIcon />
        </StyledIconButton>
      </Grid>


      {!clickable && (
      <Grid item xs={12} sx={{mb: "16px", mt:"20px"}}>
         <Divider  />
       </Grid>
      )}



      
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
        {!clickable && (
          <>
             <StyledIconButton onClick={() => setPageNumber(pageNumber - 1 )} disabled={pageNumber === 1} sx={{padding:"8px", border: "1px solid #2E3C6F", borderRadius: "8px",  transform: 'rotate(180deg)', display: "flex", justifyContent: "center", alignItems: "center"}}>
              <UISmallArrowIcon/>
              </StyledIconButton>
           
            <UIText 
                text={`Página ${pageNumber} de ${numPages}`}
                size="text-md" 
                weight="medium" 
                color="blueGray" 
            />
            <StyledIconButton onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber === numPages} sx={{padding:"8px", border: "1px solid #2E3C6F", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center"}}>
              <UISmallArrowIcon/>
            </StyledIconButton>
          </>
        )}
      </Grid>

      
    </Grid>
  );
};

export default UIPDFScrollViewer;
